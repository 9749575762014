@charset "utf-8";

$SCALE: 0.75;

@function clamp-custom($value, $scale_down: 0.6, $break_at: 1200) {
  $return_value: clamp(
    $value * $scale_down * 1px,
    ((($value / $break_at) * 100) * 1vw),
    $value * 1px
  );
  @return $return-value;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  font-size: 12px;
  height: 100%;

  @media (min-width: 768px) {
    font-size: 20px;
  }

  @media (min-width: 1200px) {
    font-size: 24px;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  position: relative;
  color: #000;
  min-width: 320px;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;

  p {
    margin: 0 !important;
  }
}

@keyframes circular-movement {
  0% {
    transform: translate(-50px, 0);
  }
  25% {
    transform: translate(0, -50px);
  }
  50% {
    transform: translate(50px, 0);
  }
  75% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(-50px, 0);
  }
}

.circle {
  animation: circular-movement 5s linear infinite alternate;
}

.circle-reverse {
  animation: circular-movement 14s linear infinite alternate-reverse;
}

.App {
  overflow: hidden;
  background-color: #f5efe6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

section {
  margin: 100px 0 200px;

  .section-title {
    font-size: 64px;
    margin-bottom: 200px;
    font-family: "Montserrat";
    font-weight: 700;
    text-align: center;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 4px;
      background-color: #000000;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -2px;
    }

    span {
      display: inline-block;
      background-color: #fff;
      padding: 0 100px;
      z-index: 22;
      position: relative;
    }
  }

  @media (max-width: 1200px) {
    margin: 100px 0 100px;

    .section-title {
      margin-bottom: 100px;
      font-size: 42px;

      span {
        padding: 0 50px;
      }
    }
  }
  @media (max-width: 992px) {
    .section-title {
      font-size: 30px;
    }
  }
  @media (max-width: 767px) {
    margin: 50px 0 100px;

    .section-title {
      font-size: 16px;
      margin-bottom: 50px;

      span {
        padding: 0 20px;
      }

      &:after {
        height: 2px;
        margin-top: -1px;
      }
    }
  }
}

@media (min-width: 1480px) {
  .container {
    max-width: 1360px;
  }
}

@media (min-width: 1680px) {
  .container {
    max-width: 1600px;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
}

.col-auto {
  max-width: 100%;
}

.btn {
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.wallet-adapter-dropdown-list-item {
  margin-left: 0;
}

.wallet-adapter-button-trigger:focus {
  outline: none !important;
}
.gg3 {
  background-color: #b1efd2cd;
  height: 450px;
  padding-top: 2rem;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
}
.gg2 {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.gg {
  height: 300px;
}
.gp {
  color: #252525cd;
  text-align: center !important;
  font-size: 0.8rem !important;
  font-weight: 400;
}
.wrp {
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  margin-top: 5rem;
  width: 80%;
  margin-left: 10%;
  padding-top: 1px;
  background-color: #b1efd2cd;
}
.hg {
  margin-top: 2rem;
  height: 54px;
  width: 50%;
  margin-left: 25%;

  font-size: 1.2rem !important;
  border-radius: 10px;
  color: #252525b6;
  text-align: center !important;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-left: clamp-custom(50 * $SCALE * 0.8);
  padding-right: clamp-custom(50 * $SCALE * 0.8);
  font-family: Agrandir;
  height: 10vh;
  flex-direction: column;
  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
  .footer-left {
    display: flex;
    margin-bottom: clamp-custom(64 * $SCALE * 0.8);
    text-transform: uppercase;
    height: clamp-custom(54 * $SCALE * 0.8);
    font-size: clamp-custom(15 * $SCALE * 0.8, 0.9);
    align-items: center;
    justify-content: center;
    min-width: 300px;
    margin-top: 40px;
    @media screen and (min-width: 900px) {
      align-self: flex-end;
      justify-content: start;
      margin-top: 0;
    }
    .footer-left-icon {
      height: clamp-custom(28);
      width: clamp-custom(30);
    }
    .footer-left-copy {
      opacity: 0.5;
      margin-left: clamp-custom(14 * $SCALE * 0.8);
    }
  }
  .footer-center {
    &.mobile {
      display: flex;
      justify-content: center;
      @media screen and (min-width: 900px) {
        display: none;
      }
    }
    &.desktop {
      display: none;
      @media screen and (min-width: 900px) {
        display: block;
      }
    }
    img {
      height: 100%;
      width: 100%;
      max-width: clamp-custom(953 * $SCALE * 0.8, 0.5, 1600);
      max-height: clamp-custom(839 * $SCALE * 0.8, 0.5, 1600);
    }
  }
  .footer-right {
    display: flex;
    gap: normal;
    opacity: 0.5;
    text-transform: uppercase;
    font-size: clamp-custom(15 * $SCALE * 0.8, 0.9);
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (min-width: 900px) {
      height: clamp-custom(54 * $SCALE * 0.8);
      flex-direction: row;
      align-self: flex-end;
      gap: clamp-custom(28 * $SCALE * 0.8);
      margin-bottom: clamp-custom(64 * $SCALE * 0.8);
      min-width: 300px;
    }
    a {
      color: #000;
      margin-bottom: 16px;
      @media screen and (min-width: 900px) {
        margin-bottom: 0;
      }
    }
  }
}

.header {
  font-family: Agrandir;
  .header-bar {
    font-weight: 900;
    background: #fff;
    color: #000;
    min-height: clamp-custom(54.4 * $SCALE, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp-custom(14.36 * $SCALE, 0.8);
    padding-left: clamp-custom(20 * $SCALE, 0.8);
    padding-right: clamp-custom(20 * $SCALE, 0.8);
    padding-top: clamp-custom(10 * $SCALE, 0.8);
    padding-bottom: clamp-custom(10 * $SCALE, 0.8);
    text-align: center;
  }
  .header-container {
    display: flex;
    justify-content: center;
    position: relative;
    height: 10vh;
  }
  .header-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: clamp-custom(25 * $SCALE * 0.8);
    @media screen and (min-width: 600px) {
      left: clamp-custom(90 * $SCALE * 0.8);
    }
    @media screen and (min-width: 1000px) {
      left: auto;
    }
    img {
      height: clamp-custom(69 * $SCALE * 0.8, 0.9);
    }
  }
  .header-right {
    margin-left: auto;
    margin-right: clamp-custom(25 * $SCALE * 0.8);
    display: flex;
    align-items: center;
    @media screen and (min-width: 600px) {
      margin-right: clamp-custom(90 * $SCALE * 0.8);
    }
    .icon-twitter {
      height: clamp-custom(20.5 * $SCALE * 0.8, 0.9);
      // margin-right: clamp-custom(20 * $SCALE * 0.8);
    }
    .icon-discord {
      height: clamp-custom(20.5 * $SCALE * 0.8, 0.9);
      // margin-right: clamp-custom(39 * $SCALE * 0.8);
    }
    .icon-linktree {
      height: clamp-custom(26 * $SCALE * 0.8, 0.9);
    }
  }
}

.subtitle {
  font-family: Agrandir;
  opacity: 0.5;
  font-weight: 900;
  font-size: clamp-custom(30 * $SCALE * 0.8);
  color: #000000;
}

.title {
  font-family: Avenida;
  font-weight: 900;
  font-size: clamp-custom(80 * $SCALE * 0.8);
  line-height: 1.3;
  color: #0f0f0f;
}

.cta-connect {
  .wallet-adapter-button {
    padding: 0 24px;
  }
  .wallet-adapter-button-trigger {
    background-color: #d9c4a0;
    color: white !important;
    border-radius: clamp-custom(38 * 0.8) !important;
    font-family: Agrandir !important;
    font-size: clamp-custom(16 * 0.8, 0.8) !important;
    height: 38px !important;
    transition: all 400ms;
    .wallet-adapter-button-start-icon {
      display: none !important;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}

.cta {
  font-family: Agrandir;
  font-weight: 900;
  height: 38px !important;
  background-color: #d8cdbc;
  // border: 2px solid #d8cdbc;
  border-radius: clamp-custom(50 * $SCALE * 0.8);
  font-size: clamp-custom(16 * 0.8, 0.8) !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  &:hover {
    color: #0f0f0f;
    text-decoration: none;
  }
}

.paragraph {
  font-family: Agrandir;
  font-weight: 900;
  font-size: clamp-custom(20 * $SCALE);
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  div {
    display: inline-flex;
  }
}
.tickets {
  font-family: Agrandir;
  display: flex;
  gap: clamp-custom(64 * $SCALE * 0.8, 0.9);
  margin-top: clamp-custom(140 * $SCALE, 0.9);
  margin-bottom: 0;
  flex-wrap: wrap;
  justify-content: center;
  .ticket {
    max-width: clamp-custom(510 * $SCALE * 0.8, 0.9);
    min-height: clamp-custom(474 * $SCALE * 0.8, 0.9);
    border: clamp-custom(4 * $SCALE * 0.8, 0.9);
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: clamp-custom(20 * $SCALE * 0.8, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    padding-top: clamp-custom(46 * $SCALE * 0.8, 0.9);
    padding-bottom: clamp-custom(46 * $SCALE * 0.8, 0.9);
    padding-left: clamp-custom(57.57 * $SCALE * 0.8, 0.9);
    padding-right: clamp-custom(57.57 * $SCALE * 0.8, 0.9);
    &:nth-child(1) {
      .ticket-title {
        max-width: clamp-custom(266 * $SCALE * 0.8, 0.9);
      }
    }
    &:nth-child(2) {
      .ticket-title {
        max-width: clamp-custom(244 * $SCALE * 0.8, 0.9);
      }
    }
    .ticket-badge {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #533021;
      color: wheat;
      font-size: clamp-custom(15 * $SCALE * 0.8, 0.9);
      line-height: 1.58;
      padding-top: 6px;
      padding-bottom: 3px;
      font-size: clamp-custom(16 * $SCALE * 0.8, 0.9);
      min-width: clamp-custom(145, 0.9);
      border-radius: clamp-custom(15, 0.9);
    }
    .ticket-number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: clamp-custom(16.61 * $SCALE * 0.8, 0.9);
      height: clamp-custom(66 * $SCALE * 0.8, 0.9);
      width: clamp-custom(66 * $SCALE * 0.8, 0.9);
      border-radius: 50%;
      background-color: black;
      color: white;
      position: absolute;
      top: clamp-custom(20 * $SCALE * 0.8, 0.9);
      left: clamp-custom(20 * $SCALE * 0.8, 0.9);
      padding-top: 3px;
    }
    .ticket-icon {
      height: clamp-custom(122 * $SCALE * 0.8, 0.9);
      width: clamp-custom(122 * $SCALE * 0.8, 0.9);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .ticket-title {
      margin-top: clamp-custom(33 * $SCALE * 0.8, 0.9);
      font-size: clamp-custom(21.56 * $SCALE * 0.8, 0.9);
      line-height: 1.37;
    }
    .ticket-content {
      margin-top: clamp-custom(33 * $SCALE * 0.8, 0.9);
      font-size: clamp-custom(16 * $SCALE * 0.8, 0.9);
      line-height: 1.58;
      opacity: 0.5;
    }
  }
}

.nfts {
  display: flex;
  font-family: Agrandir;
  gap: clamp-custom(40 * $SCALE, 0.9);
  max-width: clamp-custom(1200);
  flex-wrap: wrap;
  justify-content: center;
  margin-top: clamp-custom(172 * $SCALE, 0.9);
  margin-bottom: clamp-custom(150 * $SCALE, 0.9);
  .nft {
    display: flex;
    flex-direction: column;
    width: clamp-custom(319 * $SCALE, 0.9);
    position: relative;
    .nft-check {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      background: #fff;
      height: clamp-custom(65 * $SCALE, 0.9);
      width: clamp-custom(65 * $SCALE, 0.9);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      img {
        width: clamp-custom(23 * $SCALE, 0.9);
      }
    }
    .nft-image {
      width: clamp-custom(319 * $SCALE, 0.9);
      height: clamp-custom(296 * $SCALE, 0.9);
      border-radius: clamp-custom(15 * $SCALE, 0.9);
      &.burned {
        opacity: 0.45;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: clamp-custom(15 * $SCALE, 0.9);
      }
    }
    .nft-name {
      margin-top: clamp-custom(14 * $SCALE, 0.9);
      font-size: clamp-custom(15 * $SCALE, 0.9);
      text-align: center;
      color: #000;
      &.burned {
        opacity: 0.45;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .nft-cta {
      margin-top: clamp-custom(7 * $SCALE, 0.9);
      text-align: center;
      border-radius: clamp-custom(25 * $SCALE, 0.9);
      color: #000;
      background-color: rgb(187, 183, 183);
      width: clamp-custom(194 * $SCALE, 0.9);
      height: clamp-custom(45 * $SCALE, 0.9);
      font-size: clamp-custom(13.5 * $SCALE, 0.9);
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
      &.burned {
        color: #fff;
        background-color: #000;
        cursor: default;
      }
    }
  }
}
