@font-face {
  font-family: Agrandir;
  src: url('/public/fonts/Agrandir-GrandHeavy.otf') format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: Avenida;
  src: url('/public/fonts/Avenida_W01.otf') format("opentype");
  font-weight: 900;
}
